import React from 'react';
import { Router, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core';
import history from './modules/history'
import About from './Components/About';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import Resources from './Components/Resources';
import Candidates from './Components/Candidates';
import ComingSoon from './Components/ComingSoon';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',    
  },
  buttons: {
    color: "white",
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <HelmetProvider>
      <Router history={ history }>
        <Helmet id='test'>
          <meta property='og:title' content={'PollPlan'} />
          <meta property='og:description' content={'PollPlan'} />
        </Helmet>
        <div className={classes.container}>
          {/* <Header />
          <Route path='/' exact render={() => 
            <Home /> }
          />
          <Route path='/about' render={() => 
            <About /> }
          />
           <Route path='/resources' render={() => 
            <Resources /> }
          />
          <Route path='/candidates' render={() => 
            <Candidates /> }
          />
          <Footer /> */}
          <ComingSoon />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
