import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import middleware, { sagaMiddleware } from './middleware';
import rootSaga from '../sagas/index';
import rootReducer from '../reducers/index';

const reducer = persistReducer(
    {  
        key: 'root',
        storage: storage,
    },
    combineReducers({ ...rootReducer })
);

//const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPONE__ || CompositionEvent;

const configStore = (initialState = {}) => {
    const store = createStore(
        reducer, 
        initialState,
        applyMiddleware(...middleware)
    )

    sagaMiddleware.run(rootSaga);
    
    return {
        persistor: persistStore(store),
        store
    }
};

const { store, persistor } = configStore();
global.store = store;
export { store, persistor };