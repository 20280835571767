import React from 'react';
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        minHeight: 500,
        background: '#eff8ff',
        boxShadow: '0px 2px 5px inset #e3e5e4, 0px -2px 5px inset #e3e5e4',
        display: 'flex',
        fontFamily: 'Monaco',
        letterSpacing: -1,
        color: '#696969'
    },
    storyContainer: {
        width: '80%',
        margin: 'auto',
        minWidth: 250,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
            maxWidth: '80%',
            marginBottom: 40
        }
    },
    storyTitle: {
        fontSize: 22,
        margin: 0,
        fontWeight: 600,
        width: '95%',
    },
    storyBody: {
        fontSize: 18,
        fontWeight: 300,
    },
    list: {
        listStyle: 'none',
        width: '90%',
        padding: 5,
        letterSpacing: 0
    }
}));

const story = {
    title: "Founded in 2021, inspired by personal frustration, and built in Colorado.",
    bodyTitle: 'Our Mission is to Educate Voters and Make Voting Easier By:',
    body: [
        '1.) Putting voting resources for every state on one website',
        '2.) Create interactive maps for allowing voters to see how their vote/state matters',
        '3.) Make creating a plan for voting easy... and maybe even fun',
        '4.) Let voters collaborate for free, in one place'
    ],
    howItWorks: 'How it works:',
    works: [
        'We provide maps that show historical voting results, as well as which seats are up for election in the coming elections. ' +
        'Voters can choose between which election results they want to see or prepare for through these maps - whether it be Presidential, Senate, or House of Representatives. ' +
        'We provide information under the Voter Resources tab to explain how each election works and the importance of each type of candidate. ' +
        'Once a voter is ready, they can then go to the Plan Your Vote tab, select the state they will vote in, and follow the prompts' +
        'guiding the voter through everything going on the ballot in their state.'
    ]
}

const Map = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.storyContainer}>
                <h3 className={classes.storyTitle}>{story.title}</h3>
                <p className={classes.storyBody}>{story.bodyTitle}</p>
                <ul style={{marginLeft: -20}}>
                    {
                        story.body.map((items, index) => {
                            return <li key={index} className={classes.list}>{items}</li>
                        })
                    }
                </ul>
                <p className={classes.storyBody}>{story.howItWorks}</p>
                <ul style={{marginLeft: -20}}>
                    {
                        story.works.map((items, index) => {
                            return <li key={index} className={classes.list}>{items}</li>
                        })
                    }
                </ul>
            </div>
        </div>
    )
};

export default Map;
