import { put, all, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from '../actions/index';

export function* loadState({ state }) {
    try {
        yield put(Creators.selectState({ state }))
    }
    catch(error) {
        yield put(Creators.loadFailed(error))
    }
};

export function* loadStateBox({ show }) {
    try {
        yield put(Creators.setShowBox({ show }))
    }
    catch(error) {
        yield put(Creators.loadBoxFailed(error))
    }
};

export default function* root(){
    yield all([
        takeLatest(Types.LOAD_STATE, loadState),
        takeLatest(Types.LOAD_STATE_BOX, loadStateBox)
    ])
}