import qs from "qs";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

history.location = {
    ...history.location,
    query: qs.parse(history.location.search.substr(1)),
    state: {}
};

history.listen(() => {
    history.location = {
        ...history.location,
        query: qs.parse(history.location.search.substr(1)),
        state: history.location.state || {}
    }
}); 

const { push, goBack, go, replace } = history;
export { push, goBack, go, replace };
export default history;