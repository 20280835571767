import React, { useState, useLayoutEffect } from 'react';
import { makeStyles, MenuItem, FormControl, Select } from "@material-ui/core"
import Toggle from './Toggle';
import StatesMap from './StatesMap'
import StateSelect from './StateSelect';
import { connect } from 'react-redux';
import { Creators } from '../actions/index';
import senate from './data/senate';
import house from './data/house';
import president from './data/president';

const years = {
    'senate': Object.keys(senate),
    'house': Object.keys(house),
    'president': Object.keys(president)
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: 800,
    },
    yearStyle: {
        minWidth: 90,
    },
}));

const Map = ({ loadYear, selectedYear, toggle, yearSelect }) => {
    const [pageWidth, setWidth] = useState(window.innerWidth);
    const classes = useStyles();

    useLayoutEffect(() => {
        const updateWindowDimensions = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener('resize', updateWindowDimensions)
        return() => window.removeEventListener('resize', updateWindowDimensions)
    })

    const handleChange = (event) => {
        loadYear(event.target.value);
    };

    const findYear = () => {
        let outputYear = selectedYear
        switch(toggle){
            case 'electoral':
                if(outputYear % 2 !== 1){
                    outputYear = 2021
                    loadYear(2021)
                }
                return outputYear
            case 'senate':
                if(!years.senate.includes(outputYear)){
                    outputYear = 2022
                    loadYear(2022)
                }
                return outputYear
            case 'president':
                if(!years.president.includes(outputYear)){
                    outputYear = 2020
                    loadYear(2020)
                }
                return outputYear
            case 'house':
                    if(!years.house.includes(outputYear)){
                        outputYear = 2022
                        loadYear(2022)
                    }
                return outputYear
            case 'plan':
                return outputYear === 2022 || outputYear === 2024 ? outputYear : 2022
            default: 
                return outputYear % 2 === 0 ? outputYear : 2020
        }
    }

    return(
        <div className={classes.container}>
            <div style={{display: pageWidth < 1000 ? 'table' : 'flex', margin: 'auto', width: 'fit-content' }}>
                <div style={{width: 'fit-content', margin: pageWidth < 1000 ? 'auto' : '26px 30px 0px 30px'}}>
                    <FormControl className={classes.yearStyle} style={{marginTop: pageWidth < 1000 ? 20 : 0}}>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={findYear()}
                            onChange={handleChange}
                            style={{
                                fontWeight: 600, 
                                color: '#696969', 
                            }}
                        >
                            {
                                yearSelect.map((year, index) => {
                                    return <MenuItem key={index} value={year}>{year}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                <div style={{order: pageWidth < 1000 ? 'unset' : 'initial'}}><Toggle toggleType={'map'} /></div>
            </div>
            { pageWidth > 1000 ? <StatesMap /> : <div style={{padding: '40px 0'}}><StateSelect formTitle={'Scroll and select to plan your vote'} /></div>}
        </div>
    )
};

const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    selectedYear: state.toggle.year.year,
    yearSelect: state.toggle.yearSelect,
  });
  
const mapDispatchToProps = dispatch => ({
    loadYear: year => dispatch(Creators.loadYear(year))
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);