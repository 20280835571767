import React from 'react';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        background: '#eff8ff',
        height: '100vh',
        boxShadow: '0px 2px 5px inset #e3e5e4, 0px -2px 5px inset #e3e5e4'
    },
    soon: {
        textAlign: 'center',
        paddingTop: 100
    }
}));

const ComingSoon = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
           <h2 className={classes.soon}>PollPlan is on it's way! See you this summer!</h2>
        </div>
    )
};

export default ComingSoon;
