import React from 'react';
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: 230,
        paddingTop: 20,
        fontFamily: 'Monaco',
        color: '#696969',
        background: '#eff8ff',
        boxShadow: '0px 2px 5px inset #e3e5e4, 0px -2px 5px inset #e3e5e4'
    }
}));

const Candidates = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
           
        </div>
    )
};

export default Candidates;