import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
    loadToggle: ['selected'],
    changeToggle: ['toggle'],
    toggleFailed: ['error'],
    loadState: ['state'],
    selectState: ['selectedState'],
    loadFailed: ['error'],
    loadResourceToggle: ['selected'],
    changeResourceToggle: ['toggle'],
    toggleResourceFailed: ['error'],
    loadStateBox: ['show'],
    setShowBox: ['show'],
    loadBoxFailed: ['error'],
    loadYear: ['year'],
    changeYear: ['year'],
    yearFailed: ['error']
})