import { createReducer } from 'reduxsauce';
import { Types } from '../actions/index';
import Immutable from 'seamless-immutable';

export const INITIAL_STATE = Immutable({
    selectedState: '',
    showStateBox: true,
    loading: false,
    loadingBox: false,
});

export const loadState = state => ({
    ...state,
    loading: true
});

export const loadStateBox = state => ({
    ...state,
    loadingBox: true
});

export const selectState = (state = INITIAL_STATE, { selectedState }) => {
    return({
        ...state,
        selectedState: selectedState.state,
        selectedYear: selectedState.selectedYear,
        loading: false
    })
};

export const setShowBox = (state = INITIAL_STATE, { show }) => {
    return({
        ...state,
        showStateBox: show,
        loadingBox: false
    })
};

export const loadFailed = (state, { error }) => ({
    ...state,
    error, 
    loading: false,
});

export const loadBoxFailed = (state, { error }) => ({
    ...state,
    error, 
    loadingBox: false,
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_STATE]: loadState,
    [Types.LOAD_STATE_BOX]: loadStateBox,
    [Types.SELECT_STATE]: selectState,
    [Types.SET_SHOW_BOX]: setShowBox,
    [Types.LOAD_BOX_FAILED]: loadBoxFailed,
    [Types.LOAD_FAILED]: loadFailed
})