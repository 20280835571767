import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//redux
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from './store/index';
import { Provider } from 'react-redux';

const rootElement = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  rootElement
);