import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Button } from "@material-ui/core"
import history from '../modules/history';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'table'
    },
    title: {
       padding: '0px 4%',
       cursor: 'pointer'
    },
    routingContainer: {
        minHeight: 33,
        display: 'table',
        margin: '0px 0 10px 3%',
        [theme.breakpoints.down('xs')]: {
            margin: '0px 0 10px 1%',
        },
    },
    innerContainer: {
        display: 'table',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    buttons: {
        fontWeight: 600,
        fontSize: 12,
        color: '#838383',
        padding: '4px !important'
    }
}));

const Header = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <h2 className={classes.title} onClick={()=>history.push('/')}>PollPlan</h2>
            <div className={classes.routingContainer}>
                <div className={classes.innerContainer}>
                    <Link to='/' style={{textDecoration: 'none'}}><Button className={classes.buttons}>Home</Button></Link>
                    <Link to='/resources' style={{textDecoration: 'none'}}><Button className={classes.buttons}>Voter Resources</Button></Link>
                    <Link to='/candidates' style={{textDecoration: 'none', marginLeft: 12}}><Button className={classes.buttons}>Candidates</Button></Link>
                </div>            
            </div>
        </div>
    )
};

export default Header;
