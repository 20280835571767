import React, { useState, useLayoutEffect, useEffect } from 'react';
import { makeStyles } from "@material-ui/core"
import toggleData from './data/toggleData';
import { connect } from 'react-redux';
import { Creators } from '../actions/index';

const useStyles = makeStyles(theme => ({
    container: {
        width: 'fit-content',
        margin: 'auto',
        paddingTop: 33
    },
    innerContainer: {
        minWidth: 200,
        height: 34,
        margin: 'auto',
        border: '1px solid #696969',
        borderRadius: 10,
        display: 'flex',
        boxShadow: '2px 1px 1px #e3e5e4',
    },
    toggleItems: {
        color: '#696969',
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7
        }
    },
    toggleTitleResource: {
        marginBottom: 6,
        letterSpacing: -0.5,
        fontSize: 14,
        padding: '10px 12px 8px 12px',
        lineHeight: 1,
        [theme.breakpoints.down(1056)]: {
            fontSize: 12,
            padding: '9px 12px 8px 12px',
        },
        [theme.breakpoints.down(804)]: {
            width: '95%',
            fontSize: 11,
            padding: '6px 12px 8px 12px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '98%',
            fontSize: 10,
            letterSpacing: -1.2,
            padding: '8px 0px 0px 2px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
            letterSpacing: 0,
            padding: '10px 12px 8px 12px',
        },
    },
    toggleTitle: {
        marginBottom: 6,
        letterSpacing: -0.5,
        fontSize: 14,
        padding: '10px 12px 8px 12px',
        lineHeight: 1,
        [theme.breakpoints.down(1056)]: {
            fontSize: 13,
            padding: '9px 12px 8px 12px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '98%',
            fontSize: 11,
            letterSpacing: -1,
            padding: '8px 4px 0px 4px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
            letterSpacing: 0,
            padding: '10px 12px 8px 12px',
        },
    },
    separator: {
        borderRight: '1px solid #696969',
        height: 35,
        margin: '0px 0px 0px 12px',
    },
    houseInfo: {
        color: '#696969',
        fontSize: 15,
        textAlign: 'center',
        marginBottom: -18
    }
}));

const Toggle = ({toggle, loadToggle, toggleSelected, toggleType, loadResourceToggle, toggleSelectedResource}) => {
    const [width, setWidth] = useState(window.innerWidth);
    const classes = useStyles();

    useLayoutEffect(() => {
        const updateWindowDimensions = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener('resize', updateWindowDimensions)
        return() => window.removeEventListener('resize', updateWindowDimensions)
    })

    const updateToggle = (title) => {
        if(toggleType === 'map'){
            switch(title){
                case 'Presidential Election':
                    loadToggle({ toggle:'president', title: title }) 
                    break;
                case 'Senate':
                    loadToggle({ toggle:'senate', title: title}) 
                    break;
                case 'House of Representatives':
                    loadToggle({ toggle:'house', title: title}) 
                    break;
                case 'Electoral Votes':
                    loadToggle({ toggle:'electoral', title: title}) 
                    break;
                case 'Plan Your Vote':
                    loadToggle({ toggle:'plan', title: title}) 
                    break;
                default: 
                    loadToggle({ toggle:'president', title: title}) 
                    break;
            }
        } else {
            switch(title){
                case 'Congress':
                    loadResourceToggle({toggle: 'congress', title: title}) 
                    break;
                case 'State Elections':
                    loadResourceToggle({toggle: 'states', title: title}) 
                    break;
                case 'Primary Elections':
                    loadResourceToggle({toggle: 'primary', title: title}) 
                    break;
                case 'Electoral Votes':
                    loadResourceToggle({toggle: 'electoral', title: title}) 
                    break;
                case 'General Overview':
                    loadResourceToggle({toggle: 'general', title: title}) 
                    break;
                case 'Register!':
                    loadResourceToggle({toggle: 'register', title: title}) 
                    break;
                default: 
                    loadResourceToggle({toggle: 'congress', title: title}) 
                    break;
            }
        } 
    }

    const toggleDescription = () => {
        let toggleMessage;
        if(toggleType === 'resources'){
            return <></>
        }
        switch(toggle){
            case 'house': 
                toggleMessage = '(House of Representative seats are up for election every 2 years)';
                break;
            case 'plan':
                toggleMessage = '(Select the State you will vote in!)';
                break;
            case 'electoral':
                toggleMessage = '(Maps of districts coming soon, awaiting census results in September!)';
                break;
            default: 
                toggleMessage = '';
                break;
        }
        return <p className={classes.houseInfo}>{toggleMessage}</p>
    }

    const toggleLength = toggleData[toggleType].length;
    let toggleItemStyle = toggleType === 'map' ? classes.toggleTitle : classes.toggleTitleResource;
    let toggleSwitch = toggleType === 'map' ? toggleSelected : toggleSelectedResource;
    return (
        <div className={classes.container}>
             <div className={classes.innerContainer}>
                {
                    toggleData[toggleType].map((title, index) => {
                        return(
                            <div key={index} className={classes.toggleItems} 
                                style={{
                                    background: title === toggleSwitch ? '#696969': null,
                                    color: title === toggleSwitch ? '#fff': null,
                                    padding: (title === toggleSwitch && index === 0) ? '0px 2px' : (title === toggleSwitch && index === toggleLength - 1) ? 0 : null,
                                    borderRadius: (title === toggleSwitch && index === 0) ? '6px 0px 0px 6px' : (title === toggleSwitch && index === toggleLength - 1) ? '0px 6px 6px 0px' : null
                                }}
                                onClick={()=>updateToggle(title)}
                            >
                                <div className={toggleItemStyle}>{title}</div>
                                { index < toggleLength -1 ? <div className={classes.separator} /> : null }
                            </div>
                        ) 
                      
                    })
                }
            </div>  
            {
                toggleDescription()
            }
        </div>  
    )
};

const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    toggleSelected: state.toggle.toggleSelected,
    toggleSelectedResource: state.toggle.toggleSelectedResource,
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: selected => dispatch(Creators.loadToggle(selected)),
    loadResourceToggle: selected => dispatch(Creators.loadResourceToggle(selected))
});

export default connect(mapStateToProps, mapDispatchToProps)(Toggle);