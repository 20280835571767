import { put, all, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from '../actions/index';
import president from '../Components/data/president';
import senate from '../Components/data/senate';
import house from '../Components/data/house';

export function* loadToggle({ selected }) {
    const { toggle, title } = selected;
    try {
        let yearArray = [];
        switch(toggle){
            case 'president':
                yearArray  = Object.keys(president)
                break;
            case 'senate':
                yearArray  = Object.keys(senate)
                break;
            case 'house':
                yearArray  = Object.keys(house)
                break;
            case 'electoral':
                yearArray  = [2011, 2021]
                break;
            case 'plan':
                yearArray  = [2022, 2024]
                break;
            default:
                yearArray  = Object.keys(president)
                break;
        }
        yield put(Creators.changeToggle({toggle, title, yearArray}))
    }
    catch(error) {
        yield put(Creators.toggleFailed(error))
    }
};

export function* loadResourceToggle({ selected }) {
    const { toggle, title } = selected;
    try {
        yield put(Creators.changeResourceToggle({toggle, title}))
    }
    catch(error) {
        yield put(Creators.toggleResourceFailed(error))
    }
};

export function* loadYear({ year }) {
    try {
        yield put(Creators.changeYear({ year }))
    }
    catch(error) {
        yield put(Creators.yearFailed(error))
    }
};

export default function* root(){
    yield all([
        takeLatest(Types.LOAD_TOGGLE, loadToggle),
        takeLatest(Types.LOAD_YEAR, loadYear),
        takeLatest(Types.LOAD_RESOURCE_TOGGLE, loadResourceToggle)
    ])
}