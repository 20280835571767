import React from 'react';
import house from '../data/house';
import representatives from '../data/representatives';
import electionResults from '../data/electionResults';

import { connect } from 'react-redux';
import { Creators } from '../../actions/index';
import { makeStyles, Tooltip } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        fontFamily: 'Monaco',
        display: 'flex',
        flexFlow: 'rows',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        maxWidth: 800,
        paddingBottom: 20,
    }
}));

const Electoral = ({ selectedYear, selectedState }) => {
    const classes = useStyles();

    return(
        <div className={classes.container}>
          {/* On its way! */}
        </div>
    )
}


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    showStateBox: state.states.showStateBox.show,
    selectedState: state.states.selectedState,
    selectedYear: state.toggle.year.year,
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Electoral);