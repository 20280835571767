import React from 'react';
import house from '../data/house';
import representatives from '../data/representatives';
import electionResults from '../data/electionResults';

import { connect } from 'react-redux';
import { Creators } from '../../actions/index';
import { makeStyles, Tooltip } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        fontFamily: 'Monaco',
        display: 'flex',
        flexFlow: 'rows',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        maxWidth: 800,
        paddingBottom: 20,
    },
    reps: {
        background: '#e3e5e4',
        borderRadius: 10,
        minHeight: 90,
        paddingBottom: 10,
        cursor: 'pointer',
        '&:hover':{
            opacity: 0.7
        }
    },
    repName: {
        fontSize: 13,
        padding: '15px 0px 0px 10px',
        margin: '5px 0'
    },
    senatorParty: {
        fontSize: 12,
        fontWeight: 600,
        padding: '0px 0px 3px 10px',
        margin: 0
    },
    senatorYears: {
        fontSize: 12,
        padding: '0px 0px 2px 10px',
        margin: 0,
        color: '#696969'
    },
    senatorElection: {
        color: '#b32201',
        padding: 6,        
        margin: 'auto',
        border: '1px solid black',
        width: 'fit-content'
    },
    tooltip: {
        backgroundColor: '#e3e5e4',
        color: 'black',
        fontSize: 12
    },
    district: {
        margin: 0, 
        fontSize: 12,
        color: '#696969',
        padding: '0px 0px 2px 10px',
    }
}));

const House = ({ selectedYear, selectedState }) => {
    const classes = useStyles();

    const partyColor = (party) => {
        return party === 'Republican' ? 'red' : party === 'Independent' ? 'green' : 'blue'
    }

    const repMargin = () => {
        let numReps = representatives[selectedState.alt]
        switch(numReps){
            case 1:
                return '90px 0 0 30px'
            case 2:
                return '60px 0 -50px 30px'
            case 3:
                return '60px 0 -50px 30px'
            default:
                return 5
        }
    }

    const repsWidth = () => {
        let numReps = representatives[selectedState.alt]
        if(numReps > 22) return 120
        return 150
    }

    const repsFont = (input) => {
        let numReps = representatives[selectedState.alt]
        if(input === 'name'){
            if(numReps > 22) return 11
            return 12
        }
        if(numReps > 22) return 10
        return 11
    }

    return(
        <div className={classes.container}>
            {
                house[selectedYear][selectedState.alt].map((person, index) => {
                    return(
                        <Tooltip 
                            //className={classes.tooltip}
                            title={'Click to see Official Website'} 
                            placement="right"
                            key={index}
                        >
                            <div className={classes.reps} key={index} style={{width: repsWidth(), margin: repMargin()}}>
                                <h3 className={classes.repName} style={{fontSize: repsFont('name')}}>{person.name}</h3>
                                <p className={classes.senatorParty} 
                                    style={{fontSize: repsFont('party'), color: partyColor(person.party)}}
                                >{person.party}</p>
                                <p 
                                    className={classes.district} 
                                    style={{fontSize: repsFont('district')}}
                                >{'District: ' + `${person.district}`}</p>
                                <p 
                                    className={classes.senatorYears} 
                                    style={{
                                        marginBottom: person.next == selectedYear ? 5 : null,
                                        fontSize: repsFont('year')
                                    }}
                                >{'Since: ' + `${person.started}`}</p>
                            </div>
                        </Tooltip>
                    )
                })
            }
        </div>
    )
}


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    showStateBox: state.states.showStateBox.show,
    selectedState: state.states.selectedState,
    selectedYear: state.toggle.year.year,
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(House);