import { all, fork } from 'redux-saga/effects';
import toggle from './toggle';
import states from './states';

export default function* root(){
    yield all([
        fork(states),
        fork(toggle),
    ])
};
