import React, { useState } from 'react';
import stateData from './data/stateData';
import electionResults from './data/electionResults';
import KeyBox from './KeyBox';
import Popover from './Popover';
import { connect } from 'react-redux';
import { Creators } from '../actions/index';
import { makeStyles } from "@material-ui/core"

const STATEBUCKET = 'https://pollplan-ui.s3.us-east-2.amazonaws.com/States'

const useStyles = makeStyles(theme => ({
    statesContainer: {
        width: 1020,
        margin: 'auto',
        paddingTop: 20,
    },
    innerStatesContainer: {
        height: 675,
        display: 'table',
    },
    states: {
        position: 'relative',
        resize: 'cover',
        '&:hover': {
            opacity: 0.7
        },
        cursor: 'pointer'
    },
    keyBox: {
        marginTop: -280
    }
}));

const StatesMap = ({ toggle, selectedYear, loadState, showStateBox, loadStateBox }) => {
    const [showPopup, setShowPopup] = useState(false);
    const classes = useStyles();

    const colorFinder = (alt, title) => {
        let toggleCheck = toggle ? toggle : 'president'
        let output = `${STATEBUCKET}/${electionResults[selectedYear][toggleCheck][alt]}/${title}.png`
        return output
    };

    const stateClick = async(state) => {
        loadStateBox(true)
        loadState(state)
        setShowPopup(true)
    }

    const setPopover = () => {
        if(showPopup && showStateBox){
            return <Popover />
        }
    }

    let mapperType = toggle === 'electoral' ? 'electoral' : 'elections';
    return (
        <div className={classes.statesContainer}>
            <div className={classes.innerStatesContainer}>
            {
                stateData[mapperType].map((state, index) => {
                    return (
                        <img 
                            key={index}
                            alt={state.alt} 
                            src={colorFinder(state.alt, state.title)} 
                            className={classes.states} 
                            onClick={()=>stateClick(state)}
                            style={{ 
                                width: state.width, 
                                top: state.top ? state.top: null, 
                                right: state.right ? state.right: null, 
                                bottom: state.bottom ? state.bottom : null, 
                                left: state.left ? state.left : null
                            }}
                            />
                    )
                })
            }
            </div>
            { toggle !== 'plan' && toggle !== 'electoral' ? (
                <div className={classes.keyBox}>
                    <KeyBox />
                </div>
            ) : null } 
            {
                setPopover()
            }
        </div>
    )
}


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    selectedState: state.states.selectedState,
    showStateBox: state.states.showStateBox.show,
    selectedYear: state.toggle.year.year
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
    loadState: (state) => dispatch(Creators.loadState(state)),
    loadStateBox: show => dispatch(Creators.loadStateBox(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(StatesMap);