import React from 'react';
import { makeStyles } from "@material-ui/core"

const STATEBUCKET = 'https://pollplan-ui.s3.us-east-2.amazonaws.com/States'

const icons = {
    'blue': `${STATEBUCKET}/Blue.png`,
    'red': `${STATEBUCKET}/Red.png`,
    'split': `${STATEBUCKET}/Split.png`
}

const useStyles = makeStyles(theme => ({
    container: {
        width: 'fit-content',
        padding: '0px 20px',
        margin: 'auto',
        background: '#eff8ff',
        border: '1px solid #696969',
        borderRadius: 10,
        display: 'flex'
    },
    listContainer: {
        display: 'flex',
        padding: 4,
        fontSize: 14
    },
    square: {
        width: 30,
        height: 30,
        padding: '7px 8px 5px 4px'
    },
    party: {
        '&:hover': {
            opacity: 0.7
        },
        cursor: 'pointer'
    }
}));

const KeyBox = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.listContainer}>
                    <img alt={'blueSquare'} className={classes.square} src={icons.blue} />
                    <p className={classes.party}>Democratic Party</p>
                </div>
                <div className={classes.listContainer}>
                    <img alt={'redSquare'} className={classes.square} src={icons.red} />
                    <p className={classes.party}>Republican Party</p>
                </div>
                <div className={classes.listContainer}>
                    <img alt={'mixedSquare'} className={classes.square} src={icons.split} />
                    <p className={classes.party}>Split between two parties</p>
                </div>
            </div>
        </div>
    )
};

export default KeyBox;
