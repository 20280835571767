import { createReducer } from 'reduxsauce';
import { Types } from '../actions/index';
import Immutable from 'seamless-immutable';
import president from '../Components/data/president';

export const INITIAL_STATE = Immutable({
    toggle: 'president',
    year: 2020,
    yearSelect: Object.keys(president).reverse(),
    toggleResource: 'general',
    toggleSelected: 'president',
    toggleSelectedResource: 'general',
    loading: false,
    loadingYear: false,
    loadingResource: false,
});

export const loadToggle = state => ({
    ...state,
    loading: true
});

export const loadYear = state => ({
    ...state,
    loading: true
});

export const loadResourceToggle = state => ({
    ...state,
    loadingResource: true
});

export const changeToggle = (state = INITIAL_STATE, { toggle }) => {
    return({
        ...state,
        toggle: toggle.toggle,
        toggleSelected: toggle.title,
        yearSelect: toggle.yearArray.reverse(),
        loading: false
    })
};

export const changeYear = (state = INITIAL_STATE, { year }) => {
    return({
        ...state,
        year: year,
        loadingYear: false
    })
};

export const changeResourceToggle = (state = INITIAL_STATE, { toggle, title }) => {
    return({
        ...state,
        toggleResource: toggle.toggle,
        toggleSelectedResource: toggle.title,
        loadingResource: false
    })
};

export const toggleFailed = (state, { error }) => ({
    ...state,
    error, 
    loading: false,
});

export const toggleResourceFailed = (state, { error }) => ({
    ...state,
    errorResource: error, 
    loadingResource: false,
});

export const yearFailed = (state, { error }) => ({
    ...state,
    yearError: error, 
    loadingYear: false,
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_TOGGLE]: loadToggle,
    [Types.LOAD_YEAR]: loadYear,
    [Types.CHANGE_TOGGLE]: changeToggle,
    [Types.CHANGE_YEAR]: changeYear,
    [Types.TOGGLE_FAILED]: toggleFailed,
    [Types.LOAD_RESOURCE_TOGGLE]: loadResourceToggle,
    [Types.CHANGE_RESOURCE_TOGGLE]: changeResourceToggle,
    [Types.TOGGLE_RESOURCE_FAILED]: toggleResourceFailed,
    [Types.YEAR_FAILED]: yearFailed
})