import React from 'react';
import house from '../data/house';
import representatives from '../data/representatives';
import electionResults from '../data/electionResults';

import { connect } from 'react-redux';
import { Creators } from '../../actions/index';
import { makeStyles, Tooltip } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        fontFamily: 'Monaco',
        display: 'flex',
        flexFlow: 'rows',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        maxWidth: 800,
        paddingBottom: 20,
    },
    reps: {
        background: '#e3e5e4',
        borderRadius: 10,
        minHeight: 90,
        paddingBottom: 10,
        cursor: 'pointer',
        '&:hover':{
            opacity: 0.7
        }
    },
    repName: {
        fontSize: 13,
        padding: '15px 0px 0px 10px',
        margin: '5px 0'
    },
    senatorParty: {
        fontSize: 12,
        fontWeight: 600,
        padding: '0px 0px 3px 10px',
        margin: 0
    },
    senatorYears: {
        fontSize: 12,
        padding: '0px 0px 2px 10px',
        margin: 0,
        color: '#696969'
    },
    senatorElection: {
        color: '#b32201',
        padding: 6,        
        margin: 'auto',
        border: '1px solid black',
        width: 'fit-content'
    },
    tooltip: {
        backgroundColor: '#e3e5e4',
        color: 'black',
        fontSize: 12
    },
    district: {
        margin: 0, 
        fontSize: 12,
        color: '#696969',
        padding: '0px 0px 2px 10px',
    }
}));

const PlanYourVote = ({ selectedYear, selectedState }) => {
    const classes = useStyles();

    return(
        <div className={classes.container}>
            <h3>{selectedState.alt}</h3>
        </div>
    )
}


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    showStateBox: state.states.showStateBox.show,
    selectedState: state.states.selectedState,
    selectedYear: state.toggle.year.year,
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanYourVote);