import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import stateData from './data/stateData';

const useStyles = makeStyles(theme => ({
    container: {
        width: "45%",
        margin: 'auto',
        minHeight: 250,
        maxHeight: 500,
        display: 'table',
        background: '#f7f9ff',
        border: '1px solid #5a6ab7',
        borderRadius: 10,
        padding: '20px 0 40px 0',
        boxShadow: '2px 5px 2px #e3e5e4',
        [theme.breakpoints.down('sm')]: {
            width: '65%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '85%'
        }
    },
    innerContainer: {
        width: '100%'
    },
    title: {
        margin: '10px 0 25px 0',
        textAlign: 'center',
        fontSize: 18,
        color: '#5a6ab7'
    },
    outerScroll: {
        width: '80%',
        margin: 'auto',
        border: '1px solid #696969',
        borderRadius: 10,
        maxHeight: 255,
        overflow: 'scroll',
    },
    scrollContainer: {
        width: '100%',
        margin: 'auto',
        display: 'table',
    },
    buttonContainer: {
        width: 'fit-content',
        margin: 'auto'
    },
    button: {
        padding: '5px 10px',
        color: 'white',
        margin: '20px 0 0 0',
        height: 33,
        fontSize: 12
    },
    error: {
        fontSize: 12,
        color: 'red',
        textAlign: 'center',
        margin: '20px 0 -20px 0'
    },
    row: {
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        color: '#5a6ab7',
        height: 33,
        padding: '16px 0px 0px 0px',
    },
    error: {
        fontSize: 12,
        fontWeight: 300,
        color: 'red',
        margin: '20px 0 -10px 0',
        textAlign: 'center'
    }
}));

const ScrollSelect = ({ formTitle }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState({});
    const [showError, setShowError] = useState(false);
    const [outputObj, setOutputObj] = useState({});

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const saveData = () => {
        if(Object.keys(selected).length < 1){
            return setShowError(true);
        }
        else {
            setShowError(false);
            setOutputObj(selected);
            console.log(outputObj)
        }
    };

    const selectStyle = (row) => {
        return selected[row] ? '#eff8ff' : null
    };

    const selectItem = (row) => {
        let newSelected = selected;
        newSelected[row] ? delete newSelected[row] : newSelected[row] = row;
        setSelected(newSelected)
        forceUpdate();
    };

    const scrollOutput = (stateData) => {
        return stateData.scroll.map((row, index) => {
            return(
                <div 
                    key={index}
                    className={classes.row}
                    style={{
                        borderBottom: index < stateData.scroll.length - 1 ? '0.5px solid #e3e5e4' : null,
                        background: selectStyle(row.title)
                    }}
                    onClick={()=>selectItem(row.title)}
                >{row.title}</div>
            )
        })
    }
    return ( 
        <div className={classes.container}>
            <div className={classes.innerContainer}>
                <h3 className={classes.title}>{formTitle}</h3>
                <div className={classes.outerScroll}>
                    <div className={classes.scrollContainer}>
                        {
                            scrollOutput(stateData)
                        }
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        color='primary'
                        variant='contained'
                        className={classes.button}
                        children={'Plan your vote!'}
                        onClick={()=>saveData()}
                    />
                </div>
                { showError ? <p className={classes.error}>You must select at least one item to continue</p> : null}
            </div>
        </div>
    )
};

export default ScrollSelect;
