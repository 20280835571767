import React from 'react';
import { makeStyles } from "@material-ui/core"
import { connect } from 'react-redux';
import { Creators } from '../actions/index';
import footer from './data/footer';
import history from '../modules/history';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: 230,
        paddingTop: 20,
        fontFamily: 'Monaco',
        color: '#696969',
    },
    innerContainer: {
        marginLeft: 80, 
        display: 'flex', 
        justifyContent: 'space-between'
    },
    columns: {
        width: '25%',
        minWidth: 150
    },
    columnTitle: {
        fontSize: 15,
        fontWeight: 600
    },
    footerLinks: {
        fontWeight: 300,
        fontSize: 12,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.5,
            color: 'blue'
        }
    },
    footerLinks2: {
        fontWeight: 300,
        fontSize: 12,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.5,
            color: 'red'
        }
    },
    listContainer: {
        listStyle: 'none',
        margin: 0,
        padding: 0
    }
}));

const Footer = ({ loadResourceToggle }) => {
    const classes = useStyles();

    const linkActions = (links) => {
        switch(links){
            case 'About':
                window.scroll(0,0)
                return history.push('/about')
            case 'Overview':
                loadResourceToggle('general')
                window.scroll(0,0)
                return history.push('/resources')
            case 'Primary Elections':
                loadResourceToggle('primary')
                window.scroll(0,0)
                return history.push('/resources')
            case 'Electoral Votes':
                loadResourceToggle('electoral')
                window.scroll(0,0)
                return history.push('/resources')
            case 'Congress':
                loadResourceToggle('congress')
                window.scroll(0,0)
                return history.push('/resources')
            case 'State Elections':
                loadResourceToggle('states')
                window.scroll(0,0)
                return history.push('/resources')
            case 'Voter Registration':
                loadResourceToggle('register')
                window.scroll(0,0)
                return history.push('/resources')
            default: 
                window.scroll(0,0)
                return history.push('/resources')
        }
    }

    return (
        <div className={classes.container}>
            <div style={{width: '50%', margin: 'auto'}}>
                <div className={classes.innerContainer}>
                {
                    footer.links.map((column, index) => {
                        return(
                            <div key={index} className={classes.columns}>
                                <h3 className={classes.columnTitle}>{column.title}</h3>
                                <div style={{display: 'table'}}>
                                    <ul className={classes.listContainer}>
                                    {
                                        !column.button ? ( 
                                            column.body.map((links, index) => {
                                                return (
                                                    <li 
                                                        onClick={()=>linkActions(links)} 
                                                        key={index} 
                                                        className={ index%2===0 ? classes.footerLinks : classes.footerLinks2} 
                                                    >{links}</li>
                                                )
                                            })
                                        ) : null
                                    }
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
           </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
    loadResourceToggle: toggle => dispatch(Creators.loadResourceToggle(toggle))
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);