import React from 'react';
import { makeStyles } from "@material-ui/core";
import Map from './Map';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        background: '#eff8ff',
        boxShadow: '0px 2px 5px inset #e3e5e4, 0px -2px 5px inset #e3e5e4'
    }
}));

const Home = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
           <Map />
        </div>
    )
};

export default Home;
