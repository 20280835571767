import React from 'react';
import electionResults from './data/electionResults';
import representatives from './data/representatives';
import { connect } from 'react-redux';
import { Creators } from '../actions/index';
import { makeStyles } from "@material-ui/core"

import Senate from './popover/Senate';
import House from './popover/House';
import President from './popover/President';
import PlanYourVote from './popover/PlanYourVote';
import Electoral from './popover/Electoral';

const STATEBUCKET = 'https://pollplan-ui.s3.us-east-2.amazonaws.com/States'

const icons = {
    close:  `${STATEBUCKET}/icons/closeOut.png`,
    closeWhite:  `${STATEBUCKET}/icons/closeOutWhite.png`
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        position: 'absolute',
        top: 220,
        left: 0,
        fontFamily: 'Monaco',
        width: '100%'
    },
    popover: {
        margin: 'auto',
        borderRadius: 15,
        background: '#525e73',
        border: '1px solid #525e73',
        opacity: 0.96,
        minHeight: 400,
        height: 460,
        boxShadow: '1px 1px 0px gray',
        display: 'table',
        width: '55%'
    },
    closeOut: {
        width: '100%'
    },
    closeIcon: {
        width: 20,
        margin: 10,
        cursor: 'pointer',
        float: 'right',
        '&:hover':{
            opacity:0.7
        }
    },
    popoverHouse: {
        margin: 'auto',
        borderRadius: 15,
        background: '#525e73',
        border: '1px solid #525e73',
        opacity: 0.96,
        minHeight: 450,
        boxShadow: '1px 1px 0px gray',
        display: 'table',
    },
    popoverElectoral: {
        margin: 'auto',
        borderRadius: 15,
        background: '#525e73',
        border: '1px solid #525e73',
        opacity: 0.96,
        minHeight: 450,
        boxShadow: '1px 1px 0px gray',
        display: 'table',
        width: 450,
    },
    popoverContent: {
        display: 'flex',
        width: '90%'
    },
    popoverContentHouse: {
        margin: 'auto',
    },
    houseTitle: {
        width: '50%',
        margin: 'auto',
        height: 80,
        display: 'flex',
    },
    stateTitle: {
        color: 'white',
        fontWeight: 600,
        textAlign: 'center',
        width: 'fit-content',
        margin: 'auto',
        padding: '20px 0'
    },
    stateTop: {
        margin: '10px 0px 0px 40px',
        width: '25%'
    },
    stateBlockHouse: {
        height: 60,
        margin: '0 420px 0 0'
    },
    stateSide: {
        margin: '69px 0px 0px 40px',
        width: '45%'
    },
    stateBlock: {
        width: 160,
        margin: 0,
    },
    contentSide: {
        width: '69%'
    }
}));

const Popover = ({ selectedYear, selectedState, loadStateBox, toggle }) => {
    const classes = useStyles();

    const popoverWidth = () => {
        let numReps = representatives[selectedState.alt]
        if(numReps <= 2){
            return '90%'
        }
        else if(numReps <= 8){
            return '75%'
        }
        return '88%'
    }

    const popoverHouseWidth = () => {
        let numReps = representatives[selectedState.alt]
        if(numReps <= 2){
            return '45%'
        }
        else if(numReps <= 8){
            return '80%'
        }
        return '85%'
    }

    const ToggleSenate = () => {
        return (
            <div className={classes.popover}>
                <div className={classes.closeOut}>
                    <img 
                        onClick={()=>loadStateBox(false)} 
                        className={classes.closeIcon}
                        alt={'closeOut'}
                        src={icons.closeWhite} 
                    />
                </div>
                <h3 className={classes.stateTitle}>{selectedState.state}</h3>
                <div className={classes.popoverContent}>
                    <div className={classes.stateSide}>
                        <img 
                            className={classes.stateBlock} 
                            style={{width: selectedState.width < 50 ? 60 : null}}
                            alt={selectedState.alt} 
                            src={`${STATEBUCKET}/${electionResults[selectedYear][toggle][selectedState.alt]}/${selectedState.title}.png`}
                        />
                    </div>
                    <div className={classes.contentSide}>
                        <Senate />
                    </div>
                </div>
            </div>
        )
    }

    const TogglePresident = () => {
        return (
            <div className={classes.popover}>
                <div className={classes.closeOut}>
                    <img 
                        onClick={()=>loadStateBox(false)} 
                        className={classes.closeIcon}
                        alt={'closeOut'}
                        src={icons.closeWhite} 
                    />
                </div>
                <h3 className={classes.stateTitle}>{selectedState.state}</h3>
                <div className={classes.popoverContent}>
                    <div className={classes.stateSide}>
                        <img 
                            className={classes.stateBlock} 
                            style={{width: selectedState.width < 50 ? 60 : null}}
                            alt={selectedState.alt} 
                            src={`${STATEBUCKET}/${electionResults[selectedYear][toggle][selectedState.alt]}/${selectedState.title}.png`}
                        />
                    </div>
                    <div className={classes.contentSide}>
                        <President />
                    </div>
                </div>
            </div>
        )
    }

    const ToggleHouse = () => {
        return (
            <div className={classes.popoverHouse} style={{width: popoverHouseWidth()}}>
                <div className={classes.closeOut}>
                    <img 
                        onClick={()=>loadStateBox(false)} 
                        className={classes.closeIcon}
                        alt={'closeOut'}
                        src={icons.closeWhite} 
                    />
                </div>
                <div className={classes.houseTitle}>
                    <div className={classes.stateTop}>
                        <img 
                            className={classes.stateBlockHouse} 
                            alt={selectedState.alt} 
                            src={`${STATEBUCKET}/${electionResults[selectedYear][toggle][selectedState.alt]}/${selectedState.title}.png`}
                        />
                    </div>
                    <h3 className={classes.stateTitle}>{selectedState.state}</h3>
                </div>
                <div className={classes.popoverContentHouse} style={{width: popoverWidth()}}>
                    <div style={{margin: '20px 0'}}><House /></div>
                </div>
            </div>
        )
        
    }

    const TogglePlan = () => {
        return (
            <div className={classes.popoverHouse}>
                <div className={classes.closeOut}>
                    <img 
                        onClick={()=>loadStateBox(false)} 
                        className={classes.closeIcon}
                        alt={'closeOut'}
                        src={icons.closeWhite} 
                    />
                </div>
                <div className={classes.houseTitle}>
                    <div className={classes.stateTop}>
                        <img 
                            className={classes.stateBlockHouse} 
                            alt={selectedState.alt} 
                            src={`${STATEBUCKET}/${electionResults[selectedYear][toggle][selectedState.alt]}/${selectedState.title}.png`}
                        />
                    </div>
                    <h3 className={classes.stateTitle}>{selectedState.state}</h3>
                </div>
                <div className={classes.popoverContentHouse}>
                    <div style={{margin: '20px 0'}}><PlanYourVote /></div>
                </div>
            </div>
        )
        
    }

    const ToggleElectoral = () => {
        return(
            <div className={classes.popoverElectoral}>
                <div className={classes.closeOut}>
                    <img 
                        onClick={()=>loadStateBox(false)} 
                        className={classes.closeIcon}
                        alt={'closeOut'}
                        src={icons.closeWhite} 
                    />
                </div>
                <h3 className={classes.stateTitle}>{selectedState.state}</h3>
                <div className={classes.houseTitle}>
                    <div className={classes.stateSide}>
                        <img 
                            className={classes.stateBlock} 
                            alt={selectedState.alt} 
                            src={`${STATEBUCKET}/${electionResults[selectedYear][toggle][selectedState.alt]}/${selectedState.title}.png`}
                        />
                    </div>
                </div>
                <div className={classes.popoverContentHouse}>
                    <div style={{margin: '20px 0'}}><Electoral /></div>
                </div>
            </div>
        )
    }

    const toggleOptions = () => {
        switch(toggle){
            case 'house':
                return <ToggleHouse />
            case 'senate':
                return <ToggleSenate />
            case 'plan':
                return <TogglePlan />
            case 'president':
                return <TogglePresident />
            case 'electoral':
                    return <ToggleElectoral />
            default: 
                return <div></div>
        }
    }

    return(
        <div className={classes.container}>
            {
                toggleOptions()
            }           
        </div>
    )
}


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    showStateBox: state.states.showStateBox.show,
    selectedState: state.states.selectedState,
    selectedYear: state.toggle.year.year,
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
    loadStateBox: show => dispatch(Creators.loadStateBox(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(Popover);