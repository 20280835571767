import React from 'react';
import president from '../data/president';
import { connect } from 'react-redux';
import { Creators } from '../../actions/index';
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        fontFamily: 'Monaco',
        display: 'table'
    },
    presidents: {
        width: '100%',
        background: '#e3e5e4',
        borderRadius: 10,
        minHeight: 133,
        paddingBottom: 10,
        margin: '15px 0',
        cursor: 'pointer',
        '&:hover':{
            opacity: 0.7
        }
    },
    senatorName: {
        fontSize: 16,
        padding: '20px 0px 3px 10px',
        margin: '5px 0'
    },
    senatorParty: {
        fontSize: 14,
        fontWeight: 600,
        padding: '0px 0px 3px 10px',
        margin: 0
    },
    presidentNums: {
        fontSize: 14,
        padding: '0px 0px 2px 10px',
        margin: 0,
        color: '#696969'
    },
    senatorElection: {
        color: '#b32201',
        padding: 6,        
        margin: 'auto',
        border: '1px solid black',
        width: 'fit-content'
    },
    tooltip: {
        backgroundColor: '#e3e5e4',
        color: 'black',
        fontSize: 12
    }
}));

const President = ({ selectedYear, selectedState }) => {
    const classes = useStyles();
   
    const partyColor = (party) => {
        return party === 'Republican' ? 'red' : party === 'Independent' ? 'green' : 'blue'
    }

    return(
        <div className={classes.container}>
            {
                president[selectedYear][selectedState.alt].map((person, index) => {
                    return(
                        <div className={classes.presidents}
                         key={index}
                         style={{
                            background: index%2 === 0 ? 'white' : '#bbbbbb', 
                            boxShadow: index%2 === 0 ? '0px 3px 5px green' : null
                        }}
                        >
                            <h3 className={classes.senatorName}>{person.name}</h3>
                            <p className={classes.senatorParty} style={{color: partyColor(person.party)}}>{person.party}</p>
                            <p 
                                className={classes.presidentNums} 
                                style={{
                                    marginBottom: person.next == selectedYear ? 5 : null, 
                                    color: index%2 !== 0 ? 'gray' : null
                                }}
                            >{'Votes: ' + `${person.number}`}</p>
                            <p 
                                className={classes.presidentNums}
                                style={{
                                    color: index%2 !== 0 ? 'gray' : null
                                }}
                            >{'Percent: ' + `${person.percent}`}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    showStateBox: state.states.showStateBox.show,
    selectedState: state.states.selectedState,
    selectedYear: state.toggle.year.year,
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(President);