import React, { useEffect } from 'react';
import { 
    makeStyles, 
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Toggle from './Toggle';
import { connect } from 'react-redux';
import { Creators } from '../actions/index';
import resources from './data/resources'
 
const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        minHeight: 500,
        background: '#eff8ff',
        display: 'table',
        fontFamily: 'Monaco',
        boxShadow: '0px 2px 5px inset #e3e5e4, 0px -2px 5px inset #e3e5e4'
    },
    body: {
        width: '70%',
        margin: 'auto',
        [theme.breakpoints.down(1190)]: {
            width: '80%',
            margin: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: 'auto'
        },
    },
    accordionContainer: {
        margin: 'auto',
        paddingBottom: 80,
        width: '90%',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            display: 'table',
        },
        display: 'flex',
    },
    innerAccordionContainer: {
        margin: '30px 4% 0 4%'
    },
    accordionTitle: {
        textAlign: 'center'
    },
    accordions: {
        width: '100%',
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: 'auto !important'
        },
        [theme.breakpoints.down('md')]: {
            width: 350
        },
        [theme.breakpoints.up('lg')]: {
            width: 400
        },
    },
    heading: {
        backgroundColor: '#f5f7f6'
    },
    toggle: {
        width: '90%',
        margin: 'auto'
    },
    primaryContainer: {
        widht: '80%',
        margin: 'auto',
        paddingTop: 40
    },
    primaryTitle: {
        fontSize: 20,
        fontWeight: 300,
        color: '#696969',
    },
    primaryExample: {
        width: '90%',
        margin: 'auto',
        fontWeight: 300,
        fontSize: 15,
        padding: '0 0 80px 0',
        color: '#797d83',
    },
    list: {
        width: '90%',
        color: '#797d83',
        padding: 5,
        fontSize: 15,
        letterSpacing: 0
    }
}));

const General = ({ classes }) => {
    return (
        <div className={classes.accordionContainer}>
            <div className={classes.innerAccordionContainer}>
                <h3 className={classes.accordionTitle}>{resources.federal.title}</h3>
                {
                    resources.federal.body.map((item, index) => {
                        return(
                            <div key={index}>
                                <Accordion className={classes.accordions}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.heading}
                                    >
                                        <Typography>{item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            { item.content }
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })
                }
            </div>
            <div className={classes.innerAccordionContainer}>
                <h3 className={classes.accordionTitle}>{resources.state.title}</h3>
                {
                    resources.state.body.map((item, index) => {
                        return(
                            <div key={index}>
                                <Accordion className={classes.accordions}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.heading}
                                    >
                                        <Typography>{item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            { item.content }
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const Primary = ({ classes }) => {
    return(
        <div className={classes.primaryContainer}>
            <h3 className={classes.primaryTitle}>{resources.primary.title}</h3>
                <ul style={{marginLeft: -20}}>
                    {
                        resources.primary.body.map((items, index) => {
                            return <li key={index} className={classes.list}>{items}</li>
                        })
                    }
                </ul>
            <h3 className={classes.primaryTitle}>{resources.primary.exampleTitle}</h3>
            <p className={classes.primaryExample}>{resources.primary.example}</p>
        </div>
    )
}

const Electoral = ({ classes }) => {
    return(
        <div className={classes.primaryContainer}>
            <h3 className={classes.primaryTitle}>{resources.electoral.title}</h3>
                <ul style={{marginLeft: -20}}>
                    {
                        resources.electoral.overview.map((items, index) => {
                            return <li key={index} className={classes.list}>{items}</li>
                        })
                    }
                </ul>
                <ul style={{marginLeft: -20}}>
                    {
                        resources.electoral.more.map((items, index) => {
                            return <li key={index} className={classes.list}>{items}</li>
                        })
                    }
                </ul>
                <ul style={{margin: '0 0 80px -20px'}}>
                    {
                        resources.electoral.dates.map((items, index) => {
                            return <li key={index} className={classes.list}>{items}</li>
                        })
                    }
                </ul>
        </div>
    )
}

const State = ({ classes, type }) => {
    return (
        <div className={classes.accordionContainer}>
            <div className={classes.innerAccordionContainer}>
                <h3 className={classes.accordionTitle}>{resources.state.title}</h3>
                {
                    resources.state.body.map((item, index) => {
                        return(
                            <div key={index}>
                                <Accordion className={classes.accordions}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.heading}
                                    >
                                        <Typography>{item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            { item.content }
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const Congress = ({ classes }) => {
    return (
        <div className={classes.accordionContainer}>
            <div className={classes.innerAccordionContainer}>
                <h3 className={classes.accordionTitle}>{resources.senate.title}</h3>
                {
                    resources.senate.body.map((item, index) => {
                        return(
                            <div key={index}>
                                <Accordion className={classes.accordions}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.heading}
                                    >
                                        <Typography>{item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            { item.content }
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })
                }
            </div>
            <div className={classes.innerAccordionContainer}>
                <h3 className={classes.accordionTitle}>{resources.house.title}</h3>
                {
                    resources.house.body.map((item, index) => {
                        return(
                            <div key={index}>
                                <Accordion className={classes.accordions}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.heading}
                                    >
                                        <Typography>{item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            { item.content }
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


const Resources = ({ toggle }) => {
    const classes = useStyles();
  
    useEffect(()=> {
        window.scroll(0,0)
    },[])

    const toggleContent = () => {
        switch(toggle){
            case 'general':
                return <General classes={classes}/>
            case 'states':
                return <State classes={classes}/>
            case 'electoral':
                return <Electoral classes={classes}/>
            case 'congress':
                return <Congress classes={classes}/>
            case 'primary':
                return <Primary classes={classes}/>
            case 'register':
                return <div>register</div>
            default:
                return <div>Default</div>
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.toggle}><Toggle toggleType={'resources'} /></div>
            <div className={classes.body}>
                {
                   toggleContent()
                } 
            </div>
        </div>
    )
};


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggleResource
  });
  
const mapDispatchToProps = dispatch => ({
    loadResourceToggle: toggle => dispatch(Creators.loadResourceToggle(toggle))
});

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
