import React from 'react';
import senate from '../data/senate';
import { connect } from 'react-redux';
import { Creators } from '../../actions/index';
import { makeStyles, Tooltip } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        fontFamily: 'Monaco',
        display: 'table'
    },
    senators: {
        width: '100%',
        background: '#e3e5e4',
        borderRadius: 10,
        minHeight: 133,
        paddingBottom: 10,
        margin: '15px 0',
        cursor: 'pointer',
        '&:hover':{
            opacity: 0.7
        }
    },
    senatorName: {
        fontSize: 16,
        padding: '20px 0px 3px 10px',
        margin: '5px 0'
    },
    senatorParty: {
        fontSize: 14,
        fontWeight: 600,
        padding: '0px 0px 3px 10px',
        margin: 0
    },
    senatorYears: {
        fontSize: 14,
        padding: '0px 0px 2px 10px',
        margin: 0,
        color: '#696969'
    },
    senatorElection: {
        color: '#b32201',
        padding: 6,        
        margin: 'auto',
        border: '1px solid black',
        width: 'fit-content'
    },
    tooltip: {
        backgroundColor: '#e3e5e4',
        color: 'black',
        fontSize: 12
    }
}));

const Senate = ({ selectedYear, selectedState }) => {
    const classes = useStyles();
   
    const partyColor = (party) => {
        return party === 'Republican' ? 'red' : party === 'Independent' ? 'green' : 'blue'
    }

    return(
        <div className={classes.container}>
            {
                senate[selectedYear][selectedState.alt].map((person, index) => {
                    return(
                        <Tooltip 
                            //className={classes.tooltip}
                            title={'Click to see Official Website'} 
                            placement="right"
                            key={index}
                        >
                            <div className={classes.senators}>
                                <h3 className={classes.senatorName}>{person.name}</h3>
                                <p className={classes.senatorParty} style={{color: partyColor(person.party)}}>{person.party}</p>
                                <p 
                                    className={classes.senatorYears} 
                                    style={{marginBottom: person.next == selectedYear ? 5 : null}}
                                >{'Serving Since: ' + `${person.started}`}</p>
                                <p 
                                    className={person.next == selectedYear ? classes.senatorElection : classes.senatorYears}
                                >{'Next Election: ' + `${person.next}`}</p>
                            </div>
                        </Tooltip>
                    )
                })
            }
        </div>
    )
}


const mapStateToProps = (state) => ({
    toggle: state.toggle.toggle,
    showStateBox: state.states.showStateBox.show,
    selectedState: state.states.selectedState,
    selectedYear: state.toggle.year.year,
  });
  
const mapDispatchToProps = dispatch => ({
    loadToggle: toggle => dispatch(Creators.loadToggle(toggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Senate);